import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { message } from 'antd';
// import '../product/overlay.css'
import FeatherIcon from 'feather-icons-react';
import { getBaseURL } from '../../../Api/ApiConfig';
import { Button } from '../../../components/buttons/buttons';
import '../product/overlay.css'

function CallSteward({ isLogin }) {
    const ActionColor = localStorage.getItem("AC")
    const TextColor = localStorage.getItem('TXT');
    const TextContrast = localStorage.getItem("CTXT")

    const handleCallButtonClick = async () => {
        const isLogincode = localStorage.getItem("user_token");

        const token = localStorage.getItem('menu_token');
        const headers = {};
        headers.Authorization = `Bearer ${token}`;

        if (isLogin === "ENABLED") {
            const response = await axios.post(`${getBaseURL()}/smartPrism/callSteward?loginToken=${isLogincode}`, "", { headers });
            if (response.data.statusCode === 200) {
                // window.open(`tel:${mobileNumber}`);
                message.success('WAIT FOR STEWARD RESPONSE !');
            }
        }
        else {
            const response = await axios.post(`${getBaseURL()}/smartPrism/callSteward`, "", { headers });
            if (response.data.statusCode === 200) {
                // window.open(`tel:${mobileNumber}`);
                message.success('WAIT FOR STEWARD RESPONSE  !');
            }
        }

    };

    return (<>
        <Button size="default"style={{ background: ActionColor,boxShadow: `${TextColor}15 0px 3px 1px 1px`}} onClick={handleCallButtonClick}>
            <FeatherIcon icon="phone" size={5} style={{ color: TextContrast }} />
            <span className="text_color_contrast text_font_size_medium"style={{ color: TextContrast}}>Steward</span>
        </Button>
    </>
    );
};
CallSteward.propTypes = {
    // isCallSteward: PropTypes.string,
    isLogin: PropTypes.string,
};
export default CallSteward;